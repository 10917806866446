import React from 'react'
import styled from 'styled-components'
import UserBar from './user/UserBar';
import media from '@/ui/media';
import SearchBar from './search/SearchBar';
import DateSelectorBar from './booking-filter/DateSelectorBar';
import { Link, useNavigate } from 'react-router-dom';
import { useProject } from '@/hooks/useProject';
import { useGlobalStore } from '@/stores/globalStore';
import { useTree } from '@/api/hooks/useTree';

const Appbar: React.FC = () => {
    return (
        <Wrapper>
            <SearchBar />
            {/* <DateSelectorBar /> */}
            <Home />
            <UserBar />
        </Wrapper>
    )
}

export default Appbar

const Home = () => {
    const setSeat = useGlobalStore(state => state.setSeat)
    const setActiveLayer = useGlobalStore(state => state.setActiveLayer)
    const navigate = useNavigate()
    const { layers } = useTree()

    
    const onHomeClick = (e) => {
        e.preventDefault()
        navigate('/')
        setSeat(null)
        if (layers && layers.layer) {
            setActiveLayer(Number(layers.layer.id))
        }
    }

    return (
        <HomeWrapper>
            <HomeButton href="/" onClick={onHomeClick}>
                <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="20px" height="20px">
                    <path d="M 15 2 A 1 1 0 0 0 14.300781 2.2851562 L 3.3925781 11.207031 A 1 1 0 0 0 3.3554688 11.236328 L 3.3183594 11.267578 L 3.3183594 11.269531 A 1 1 0 0 0 3 12 A 1 1 0 0 0 4 13 L 5 13 L 5 24 C 5 25.105 5.895 26 7 26 L 23 26 C 24.105 26 25 25.105 25 24 L 25 13 L 26 13 A 1 1 0 0 0 27 12 A 1 1 0 0 0 26.681641 11.267578 L 26.666016 11.255859 A 1 1 0 0 0 26.597656 11.199219 L 25 9.8925781 L 25 6 C 25 5.448 24.552 5 24 5 L 23 5 C 22.448 5 22 5.448 22 6 L 22 7.4394531 L 15.677734 2.2675781 A 1 1 0 0 0 15 2 z M 18 15 L 22 15 L 22 23 L 18 23 L 18 15 z" />
                </svg>
            </HomeButton>
        </HomeWrapper>
    )
}

const HomeWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const HomeButton = styled.a`
    background: #2C2C2C;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;

    cursor: pointer;
`

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 32px;
    padding: 0 20px;
    height: 75px;
    box-shadow: 4px 0px 40px rgba(84, 84, 84, 0.25);
    /* border-radius: 0px 0px 8px 8px; */
    z-index: 2001;
    background: ${props => props.theme.palette.background.primary};
    color: ${props => props.theme.palette.text.primary};

    ${media.lg`
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 16px;
        padding: 0 10px;
        border-radius: 0px;
    `}
`