import React from 'react'
import { useGlobalStore } from '@/stores/globalStore'
import InfoIcon from '../icons/InfoIcon'
import MobileEmployeeItem from './MobileEmployeeItem'
import { formatPhoneNumber } from '@/utils/helpers/phone.helpers'

export const EmployeeMobileListItem: React.FC<any> = ({ user }) => {
  const { setEmployee } = useGlobalStore()
  const { id, display, fields } = user

  const openUser = () => setEmployee(id)

  return (
    <MobileEmployeeItem name={display} fields={fields} openUser={openUser} />
  )
}

const EmployeeListItem: React.FC<any> = ({ template, columns, item }) => {
  const { setEmployee } = useGlobalStore()
  const { id, data } = item

  const openUser = () => setEmployee(id)

  return (
    <tr>
      {data.map(({ label, value, type }, idx) => (
        <td key={idx}>{type === 'phone' ? formatPhoneNumber(value) : value}</td>
      ))}
      {/* <Grid.Item>{item.id}</Grid.Item>
            <Grid.Item>{item.name}</Grid.Item>

            <Grid.Item>{item.department || 'Не указано'}</Grid.Item>
            <Grid.Item>{item.email}</Grid.Item>
            <Grid.Item>{item.phone}</Grid.Item> */}
      <td>
        <a href="#" onClick={openUser}>
          <InfoIcon />
        </a>
      </td>
    </tr>
  )
}

export default EmployeeListItem
