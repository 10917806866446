import { UserData } from '@/api/user'

type UserItem = {
  id: number
  name: string
  department: string
  email: string
  phone: string
}

export type UserExtra = {
  label: string
  accessor: string
  uid: string
  alias: string
  type: string
}

export const makeEmployeeData = (
  data: UserData[] | undefined,
  columns: UserExtra[] | undefined
) => {
  if (!data || !columns) return []

  return data.map((user) => {
    const userData = [
      {
        label: 'display',
        value: user.display,
        type: 'display',
        alias: 'display'
      }
    ]

    columns.forEach((col) => {
      userData.push({
        label: col.label,
        value: user[col.uid] || '',
        type: col.type,
        alias: col.alias
      })
    })

    return { id: Number(user.id), data: userData }
  })
}

export const makeEmployeeMobileData = (
  data: UserData[] | undefined,
  columns: UserExtra[] | undefined
) => {
  if (!data || !columns) return []

  return data.map((user) => {
    const userData: any = {
      id: Number(user.id),
      display: user.display,
      fields: []
    }

    columns.forEach((col) => {
      if (user[col.uid]) {
        userData.fields.push({
          name: col.label,
          value: user[col.uid]
        })
      }
    })

    return userData
  })
}
