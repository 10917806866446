export class BomjMapper {
  public static getColumns(report = [] as any[]) {
    console.log(report)
    if (!report.length) {
      return []
    }
    return Object.keys(report[0]).filter(
      (v) => !['id', 'enabled', 'login', 'role', 'upd'].includes(v)
    )
  }

  public static getTranslations(columns, userProps) {
    const translations = {}

    userProps.forEach((prop) => {
      if (columns.includes(prop.key)) {
        translations[prop.key] = prop.label
      }
    })

    return translations
  }
}
