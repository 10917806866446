import { Layer } from "react-konva";
import { memo } from "react";
import Polygon from "./Polygon";
import { useMapStore } from "@/stores/mapStore";

const PolygonsLayer: React.FC<any> = ({ polygons = [] }) => {
  const [width, height] = useMapStore((state) => state.size);

  return (
    <Layer>
      {polygons.map(({ id, polygon }) => (
        <Polygon
          id={id}
          key={id}
          width={width}
          height={height}
          polygon={polygon}
        />
      ))}
    </Layer>
  );
};

export default memo(PolygonsLayer);
