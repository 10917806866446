import React from 'react'
import { useFormikContext, FieldArray } from 'formik';
import BookingInterval, { NoBookingIntervals } from './BookingInterval';
import useResponsive from '@/hooks/useResponsive';


const BookingIntervalField = ({ bindArrayHelpers }) => {
    const { values } = useFormikContext<any>()
    const { isMobile } = useResponsive()

    if (values.type !== '1') return null

    return (
        <FieldArray
            name="dates"
            render={arrayHelpers => {
                bindArrayHelpers(arrayHelpers)

                return (
                    <div style={{ width: '100%' }}>
                        {values.dates?.map((interval, index) => (
                            <BookingInterval
                                key={index}
                                name="dates"
                                interval={interval}
                                index={index}
                                count={values.dates.length}
                                helpers={arrayHelpers}
                            />
                        ))}
                        {(!values.dates.length || isMobile) && (
                            <NoBookingIntervals helpers={arrayHelpers} />
                        )}
                    </div>
                )
            }}
        />
    )
}

export default BookingIntervalField