import React, { Component, useEffect, useState } from 'react'
import ModalService from '@/components/shared/modal/ModalService'
import { animated, useTransition } from 'react-spring'
import { easePoly } from 'd3-ease'
import styled from 'styled-components'
import {
  FORCE_CLOSE_MODALS,
  OPEN_MODAL
} from '@/components/shared/modal/modal.utils'

// interface IModal {
//     component: React.ReactNode
//     props: any
//     close?: Function
// }

export const dispatchCloseModals = () => {
  const closeEvent = new CustomEvent(FORCE_CLOSE_MODALS)

  document.dispatchEvent(closeEvent)
}

const ModalRoot = () => {
  const [modal, setModal] = useState<any>({})

  const transitions = useTransition(modal, {
    from: { opacity: 0, transform: 'scale(1.1)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(1.1)' },
    config: {
      duration: 250,
      easing: easePoly.exponent(2)
    }
  })

  const closeAllmodals = () => setModal({})

  useEffect(() => {
    ModalService.on(OPEN_MODAL, ({ component, props }) => {
      setModal({
        component,
        props,
        close: () => {
          setModal({})
        }
      })
    })

    document.addEventListener(FORCE_CLOSE_MODALS, closeAllmodals, true)
    return () =>
      document.removeEventListener(FORCE_CLOSE_MODALS, closeAllmodals, true)
  }, [])

  return transitions((style, { component: Component }) =>
    Component ? (
      <ModalWrapper style={style}>
        <Component
          {...modal.props}
          close={modal.close}
          // className={ModalComponent ? 'd-block' : ''}
        />
      </ModalWrapper>
    ) : (
      ''
    )
  )
}

export default ModalRoot

const ModalWrapper = styled(animated.div)<{
  $center?: boolean
  $maxWidth?: number
}>`
  display: flex;
  justify-content: center;
  background: rgba(31, 31, 31, 0.8);
  z-index: 10001;
  padding: 10px 15px;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
`
