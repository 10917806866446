import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import CloseIcon from './icons/CloseIcon'
import Grid from './Grid'
import { animated, useTransition } from 'react-spring'
import { easePoly } from 'd3-ease'
import { useGlobalStore } from '@/stores/globalStore'
import { useMetadata } from '@/api/hooks/useMetadata'
import { ErrorBoundary } from 'react-error-boundary'
import { OfficeMapIcon } from './icons'
import { useLayerInfo } from '@/api/hooks/useLayerInfo'
import { linkLayerInfoProperties } from './layout/Sidebar/Layers/LayerInfoModal'
import { formatBooleanValue } from './SeatBar'
import { translate } from '@/i18n'
import useOnClickOutside from '@/hooks/useOnClickOutside'

const format = (str: string | undefined) => str || ''

const LayerBar = () => {
  const [section, setSection] = useState<'info' | null>(null)

  // data selectors
  const layerModal = useGlobalStore((state) => state.layerModal)
  const setLayerModal = useGlobalStore((state) => state.setLayerModal)
  const setPopupLayer = useGlobalStore((state) => state.setPopupLayer)

  // fetch data hook
  const { data } = useLayerInfo(layerModal)
  const { metadata } = useMetadata()

  const currentNode = useMemo(() => {
    if (data && metadata && metadata.layers) {
      return metadata.layers[data.info.type_uid]
    }
    return null
  }, [data, metadata])

  const props = linkLayerInfoProperties(
    currentNode?.plugin_data,
    data?.info?.plugin_data
  )
  const onClose = () => setLayerModal(null)
  const place = data?.info.parent_name.join('. ') || ''

  const isPopup = useMemo(
    () =>
      !!props.find((prop) => prop.name === 'popup' && prop.value === 'true'),
    [props]
  )
  const isOpen = layerModal && currentNode && !currentNode.ownView && !isPopup

  useEffect(() => {
    setPopupLayer(layerModal)
  }, [isPopup])

  useEffect(() => {
    if (!layerModal) {
      setTimeout(() => {
        setSection(null)
      }, 300)
    }

    if (layerModal) {
      setSection(null)
    }
  }, [layerModal])

  const transitions = useTransition(isOpen, {
    from: { opacity: 0, translateY: 100, translateX: '-50%' },
    enter: { opacity: 1, translateY: 0, translateX: '-50%' },
    leave: { opacity: 0, translateY: 100, translateX: '-50%' },
    config: {
      duration: 300,
      easing: easePoly.exponent(2)
    }
  })

  const toggleSection = (name) => {
    if (section && section !== name) {
      setSection(name)
    } else if (section === null) {
      setSection(name)
    } else {
      setSection(null)
    }
  }

  const refWrapper = useRef<HTMLDivElement>(null)

  useOnClickOutside(refWrapper, onClose)

  return transitions((style, item) =>
    item ? (
      <Wrapper ref={refWrapper} style={style}>
        {!section && <Title>{format(data?.info.name)}</Title>}
        {section === 'info' && (
          <Close onClick={onClose}>
            <CloseIcon color="#000" />
          </Close>
        )}
        {!section && (
          <SectionsWrapper>
            <Section
              $active={section === 'info'}
              onClick={toggleSection.bind(null, 'info')}
            >
              <OfficeMapIcon />
              <SectionTitle $expanded={section === null}>
                {translate('info')}
              </SectionTitle>
            </Section>
            <Section onClick={onClose}>
              <CloseIcon color="#000" />
              <SectionTitle $expanded={section === null}>
                {translate('close')}
              </SectionTitle>
            </Section>
          </SectionsWrapper>
        )}
        <Container $expanded={section !== null}>
          {section === 'info' && (
            <Content>
              <SectionItem>
                <ItemTitle>{format(data?.info.name)}</ItemTitle>
              </SectionItem>

              {currentNode?.name && (
                <SectionItem>
                  <Item>{translate('type')}</Item>
                  <Item $bold>{format(currentNode?.name)}</Item>
                </SectionItem>
              )}

              {place && (
                <SectionItem>
                  <Item>{translate('position')}</Item>
                  <Item $bold>{format(place)}</Item>
                </SectionItem>
              )}

              {data?.info.info && (
                <SectionItem>
                  <Item>{translate('description')}</Item>
                  <Item $bold>{format(data?.info.info)}</Item>
                </SectionItem>
              )}

              <ErrorBoundary fallback={<></>}>
                {props.map((property) => (
                  <SectionItem key={property.uid}>
                    <Item>{property.name}</Item>
                    <Item $bold>{formatBooleanValue(property.value)}</Item>
                  </SectionItem>
                ))}
              </ErrorBoundary>
            </Content>
          )}
        </Container>
      </Wrapper>
    ) : (
      ''
    )
  )
}

export default LayerBar

const Content = styled.div`
  margin-top: 42px;
  margin-bottom: 16px;
`

const Close = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;

  &::before {
    content: ' ';
    position: absolute;
    border-radius: 50%;
    top: -5px;
    left: -5px;
    width: 40px;
    height: 40px;
    background: #fff975;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }
`

const ItemTitle = styled.div`
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 12px;
`

const Item = styled.div<{ $bold?: boolean }>`
  padding-right: 16px;
  min-width: 150px;
  /* font-size: 12px;
    line-height: 16px; */

  ${({ $bold }) =>
    $bold &&
    css`
      font-weight: 700;
      min-width: 150px;
      padding-right: 0;
    `}
`

const SectionItem = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`

const Container = styled.div<{ $expanded: boolean }>`
  margin: 1rem 1.6rem;
  max-height: 0;
  height: auto;
  transition: all 0.5s;
  overflow: hidden;

  ${Grid.Row} {
    padding: 1rem 0;
  }

  ${({ $expanded }) =>
    $expanded &&
    css`
      max-height: 500px;
    `}
`

const Title = styled.div`
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-align: center;
  color: #000000;
  font-weight: 600;
  padding: 10px 0;
`

const Wrapper = styled(animated.div)`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-height: 100%;
  max-width: 400px;
  min-height: 90px;
  width: 100%;
  z-index: 9101;
  overflow-y: auto;

  background: #ffffff;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
`

const SectionsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 1rem 1rem 0 1rem;
`

const Section = styled.div<{ $active?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-basis: 33.3%;
  cursor: pointer;
  position: relative;
  z-index: 10;

  &::before {
    content: ' ';
    position: absolute;
    border-radius: 50%;
    top: 0;
    width: 40px;
    height: 40px;
    background: #fff975;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s;
  }

  ${({ $active }) =>
    $active &&
    css`
      &::before {
        opacity: 1;
      }
    `}
  &:hover {
    &::before {
      opacity: 1;
    }
  }
`

const SectionTitle = styled.div<{ $expanded: boolean }>`
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-align: center;
  color: #000000;
  padding-top: 0.8rem;
  max-height: 0;
  height: auto;
  transition: all 0.5s;
  overflow: hidden;

  ${({ $expanded }) =>
    $expanded &&
    css`
      max-height: 30px;
    `}
`
