import { useQuery } from 'react-query'
import { useToast } from '@/components/shared/toast/useToast'
import { useProject } from '@/hooks/useProject'
import { UserService } from '../services/user.service'

export const useUserCardFields = () => {
  const { workspaceId, projectId } = useProject()
  const { enqueueToast } = useToast()

  const { data, isLoading, refetch } = useQuery(
    ['runtime_fields', workspaceId, projectId],
    () =>
      UserService.getRuntimeFields({
        workspaceId: Number(workspaceId),
        projectId: Number(projectId)
      }),
    {
      enabled: !!(workspaceId && projectId),
      refetchOnMount: false,
      select: ({ data }) => ({
        fields: data?.required_fields || [],
        props: data?.required_fields.reduce((acc, val) => {
          acc[val.uid] = val
          return acc
        }, {})
      }),
      onError: () => {
        enqueueToast(
          {
            title: 'Ошибка!',
            message: 'Не удалось загрузить список дополнительных полей'
          },
          { variant: 'error' }
        )
      }
    }
  )

  return { data, isLoading, refetch }
}
